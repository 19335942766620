
  import Vue, {PropType} from "vue";
  import PwaIcon from '@/components/common/PwaIcon.vue';
  import PwaDatePicker from '@/components/common/PwaDatePicker.vue';
  import {ROUTES} from "@/router/routes";
  import { countries } from "@/services/api/mocks/geolocation";
  import { timezones, timezonesLinks } from "@/services/api/mocks/timezones";
  import moment from "moment-timezone";
  import {LANGS} from "@/utils/i18n";
  import {getInputErrorMessage, hasErrors, hasRequiredNotFilled} from "@/utils/form";
  import PwaEmailVerification from "@/components/PwaEmailVerification.vue";
  import { EVENTS_NAME } from '@/services/analytics';
  import { AuthActionTypes } from '@/store/modules/auth/actions';
  import {MODAL_IDS} from "@/utils/modal";
  import {SocialLoginType} from "@/utils/social-login";

  const REQUIRED_ITEMS = ['name', 'username', 'email', 'password', 'country', 'birthdate'];
  const ALL_FIELDS = [...REQUIRED_ITEMS, 'friend_code', 'acceptConditions'];
  const birthdateFormat = 'YYYY-MM-DD';

  export default Vue.extend({
    name: "pwa-sign-up",
    components: {
      PwaIcon,
      PwaDatePicker,
    },
    data() {
      return {
        showPassword: false,
        routes: ROUTES,
        langs: LANGS,
        acceptAll: false,
        acceptConditions: false,
        acceptPrivacyPolice: false,
        form: {
          name: '',
          username: '',
          email: '',
          password: '',
          birthdate: null,
          country: null,
          timezone: '',
          friend_code: '',
          newsletter: false,
          third_parties: false,
        } as any,
        errors: {} as any,
        filledUserData: null as any,
        referUsernameValue: this.referUsername,
      }
    },
    props: {
      isModal: {
        type: Boolean,
        default: false,
      },
      onSignInClick: {
        type: Function as PropType<() => void>,
        default: () => {/** Nothing to do*/
        },
      },
      referUsername: {
        type: String,
        required: false,
        default: null,
      },
      socialLogin: {
        type: Object as PropType<SocialLoginType & {user: Record<string, string>}>,
        required: false,
        default: null,
      },
      shownFields: {
        type: Object,
        required: false,
        default: null,
      },
      hideSignIn: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    mounted(): void {
      this.cancelShowModalByIdWithDelay(MODAL_IDS.DEPOSIT_REMINDER);

      let friendCode = null;
      if(this.referUsername){
        friendCode = this.referUsername;
      }else if(this.$store.state.auth.friendCode){
        friendCode = this.$store.state.auth.friendCode;
      }

      if (friendCode) {
        this.$set(this.form, 'friend_code', friendCode);
        this.onFriendCodeChange();
      }
      this.referUsernameValue = friendCode;
    },
    computed: {
      hasErrors(): boolean {
        return hasErrors(this.errors);
      },
      requiredItems(): string[] {
        const requiredItems: string[] = [];

        REQUIRED_ITEMS.forEach((fieldName: string) => {
          if (!this.hiddenFields[fieldName]) {
            requiredItems.push(fieldName);
          }
        });

        return requiredItems;
      },
      canSignUp(): boolean {
        return !this.hasRequiredNotFilled && !this.hasErrors;
      },
      hasRequiredNotFilled(): boolean {
        return hasRequiredNotFilled(this.requiredItems, this.form);
      },
      typeInputPassword(): string {
        return this.showPassword ? 'text' : 'password';
      },
      countryList(): {name_es: string; name_en: string; code: string}[] {
        const columnName = this.currentLang === LANGS.ES ? 'name_es' : 'name_en';
        const countriesList = [...countries];
        countriesList.sort((a, b) => {
          if (a.code === 'ES') {
            return -1;
          } else if (b.code === 'ES') {
            return 1;
          } else {
            return a[columnName] < b[columnName] ? -1 : a[columnName] > b[columnName] ? 1 : 0;
          }
        });
        return countriesList;
      },
      socialLoginUser(): Record<string, string> {
        return this.socialLogin ? this.socialLogin.user : null;
      },
      userData(): Record<string, string> {
        return this.socialLoginUser ? this.socialLoginUser : this.filledUserData;
      },
      nextUrl(): string {
        return this.$store.getters.nextUrl;
      },
      hiddenFields(): Record<string, boolean> {
        const fields: Record<string, boolean> = {};
        if (this.userData) {
          Object.keys(this.userData).forEach(fieldName => {
            fields[fieldName] = true;
          });

          fields.password = true;
        } else if (this.shownFields && !this.filledUserData) {
          ALL_FIELDS.forEach((fieldName: string) => {
            fields[fieldName] = !this.shownFields[fieldName];
          });
        }

        return fields;
      },
      minAge(): number {
        return Number(process.env.VUE_APP_MIN_AGE);
      },
      maxDate(): string {
        return moment().subtract(this.minAge, 'years').format(birthdateFormat);
      },
    },
    methods: {
      signUpSubmit() {
        if (this.shownFields && !this.userData) {
          if (!this.hiddenFields.acceptConditions) {
            if(!this.acceptConditions){
              this.showToastError(this.$t('139'), this.$t('308'));
              return;
            }else if(!this.acceptPrivacyPolice){
              this.showToastError(this.$t('139'), this.$t('324'));
              return;
            }
          }

          this.showLoader(true);
          const filledData: Record<string, string> = {};
          Object.keys(this.shownFields).forEach((fieldName: string) => {
            filledData[fieldName] = this.form[fieldName];
          });

          this.filledUserData = filledData;
          // Se falsea espera para parecer que estamos haciendo algo
          setTimeout(() => {
            this.$emit('shownFieldsFilled');
            this.showLoader(false);
          }, 300);
        } else {
          this.signUp();
        }
      },
      async signUp() {
        try {
          if(!this.acceptConditions){
            this.showToastError(this.$t('139'), this.$t('308'));
          }else if(!this.acceptPrivacyPolice){
            this.showToastError(this.$t('139'), this.$t('324'));
          }else{
            this.showLoader(true);
            this.form.lang = this.currentLang;
            this.form.timezone = this.getUserTimezoneData();
            const formData = {...this.form};
            if (this.$store.getters.hasUserSource) {
              formData.user_source = this.$store.state.userSource;
            }

            if (this.socialLogin) {
              // No se envian los datos que ya tenemos por el social login
              Object.keys(this.socialLoginUser).forEach(key => {
                delete formData[key];
              });

              delete formData.password;

              formData.social = {
                platform: this.socialLogin.platform,
                data: this.socialLogin.data,
              };
            }

            const referer = this.$route.params.lang ? this.$route.path.replace(`/${this.$route.params.lang}/`, '/') : this.$route.path;
            formData.referer = referer;

            const { data } = await this.$http.auth.postSignUp(formData);
            await this.$store.dispatch(AuthActionTypes.SET_FRIEND_CODE, null);
            this.sendAnalyticsEvent(EVENTS_NAME.REGISTER);
            if(this.isNativeApp){
              this.sendAnalyticsEvent(EVENTS_NAME.REGISTER_APP);
            }
            const token = data?.data?.token;
            if (token) {
              this.onSignUpWithTokenSuccess(token);
            } else {
              this.showLoader(false);
              const message = this.$createElement(PwaEmailVerification, {
                props: {subtitle: this.$t('430')},
              });
              this.showModal({
                title: this.$t('301'),
                message: message,
                hideCancel: true,
                showOnRoot: true,
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                contentClass: {'send-mail': true, 'is-desktop': this.isDesktop},
                onOkClick: () => {
                  //Soluciona error de typescript ya que la propiedad no es obligatoria
                  if (this.onSignInClick) {
                    this.onSignInClick();
                  }
                }
              });
            }

          }
        } catch (e) {
          const response = e?.response || {};
          const title = this.$t('139');
          if (response.status  === 503 && response?.data?.message) {
            this.showToastError(title, response.data.message);
          } else {
            this.showToastError(title, e.response.data.errors);
          }

          this.showLoader(false);
        }
      },
      async onCheckFieldBlur(event: Event) {
        const input = event.target as HTMLInputElement;
        const field = input.name;
        let errorMessage = this.getInputErrorMessage(event);
        if (!errorMessage) {
          if (await this.checkExistUsernameOrEmail(field)) {
            errorMessage = this.$t('75', {field: input.placeholder.toLowerCase()});
          }
        }

        this.$set(this.errors, field, errorMessage);
      },
      async checkExistUsernameOrEmail(field: string) {
        let exist = false;
        let value = this.form[field];

        if(field == 'username'){
          this.form.friend_code = this.form.friend_code.trim();
          value = value.trim();
          value = value.replace(/\s/g, '-');
          this.form[field] = value;
        }

        if (value) {
          const searchData = {[field]: value};
          try {
            const {data} = await this.$http.auth.postCheckField(searchData);
            exist = data.data;
          } catch (error) {
            this.showToastError(this.$t('139'), 'Se ha producido un error al comprobar el ' + field);
          }
        }

        return exist;
      },
      onInputBlur(event: Event) {
        const input = event.target as HTMLInputElement;
        const field = input.name;
        const errorMessage = this.getInputErrorMessage(event);

        this.$set(this.errors, field, errorMessage);
      },
      onDateSelected(date: string) {
        const field = 'birthdate';
        this.form[field] = date;
        let errorMessage = date ? '' : this.$t('58', {field});
        if (!errorMessage) {
          const dateSelected = moment(date, birthdateFormat);
          const maxDate = moment(this.maxDate, birthdateFormat);
          if (dateSelected > maxDate) {
            errorMessage = this.$t('351', {years: this.minAge});
          }
        }
        this.$set(this.errors, field, errorMessage);
      },
      getInputErrorMessage(event: Event) {
        const input = event.target as HTMLInputElement;
        const field = input.name;
        const value: string = this.form[field];
        return getInputErrorMessage(input, value);
      },
      onShowPassword() {
        this.showPassword = !this.showPassword;
      },
      async onFriendCodeChange() {
        let errorMessage = '';
        if (this.form.friend_code && !await this.checkExistsFriendCode()) {
          errorMessage = this.$t('77', {field: this.$t('67').toLowerCase()});
        }

        this.$set(this.errors, 'friend_code', errorMessage);
        if (errorMessage && this.referUsernameValue) {
          this.referUsernameValue = null;
        }
      },
      async checkExistsFriendCode() {
        let existFriendCode = false;
        if (this.form.friend_code) {
          try {
            this.form.friend_code = this.form.friend_code.trim();
            const {data} = await this.$http.user.getCheckFriendCode({code: this.form.friend_code});
            existFriendCode = data.data;
          } catch (error) {
            this.showToastError(this.$t('139'), 'Se ha producido un error al comprobar el código amigo');
          }
        }

        return existFriendCode;
      },
      getUserTimezoneData() {
        let value = this.getUserTimezone();
        if (timezonesLinks[value]) {
          value = timezonesLinks[value];
        }
        const timezone = timezones.find(timezone => timezone.value === value);
        let name = '';
        if (timezone) {
          name = timezone.name;
        } else {
          const timezonesValues = value.split('/');
          if (timezonesValues.length > 0) {
            name = timezonesValues.pop();
          }
        }

        return {value, name};
      },
      async onSignUpWithTokenSuccess(token: string) {
        if(this.allowDeposit){
          this.setNextUrl(`/${ROUTES.deposit.path}`);
        }
        await this.onLoginSuccess(token);

        if (this.isModal) {
          this.hideModalById(MODAL_IDS.SIGN_UP);
        }

        this.showLoader(false);
        this.showToastSuccess(this.$t('359'), this.$t('576'));
      },
    },
    watch: {
      acceptConditions(){
        if(this.acceptAll && !this.acceptConditions){
          this.acceptAll = false;
        }
      },
      acceptPrivacyPolice(){
        if(this.acceptAll && !this.acceptPrivacyPolice){
          this.acceptAll = false;
        }
      },
      socialLoginUser(){
        if(this.socialLoginUser){
          Object.keys(this.socialLoginUser).forEach(field => {
            this.$set(this.errors, field, '');
          });
        }
      },
      'form.newsletter'(){
        if(this.acceptAll && !this.form.newsletter){
          this.acceptAll = false;
        }
      },
      'form.third_parties'(){
        if(this.acceptAll && !this.form.third_parties){
          this.acceptAll = false;
        }
      },
      acceptAll() {
        if(this.acceptAll){
          this.acceptConditions = true;
          this.acceptPrivacyPolice = true;
          this.form.newsletter = true;
          this.form.third_parties = true;
        }
      },
    }
  });
