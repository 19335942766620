export const countries = [
  {
    "name_en": "Spain",
    "name_es": "España",
    "dial_code": "+34",
    "code": "ES"
  },
  {
    "name_en": "Afghanistan",
    "name_es": "Afganistan",
    "dial_code": "+93",
    "code": "AF"
  },
  {
    "name_en": "Albania",
    "name_es": "Albania",
    "dial_code": "+355",
    "code": "AL"
  },
  {
    "name_en": "Algeria",
    "name_es": "Argelia",
    "dial_code": "+213",
    "code": "DZ"
  },
  {
    "name_en": "AmericanSamoa",
    "name_es": "Samoa Americana",
    "dial_code": "+1 684",
    "code": "AS"
  },
  {
    "name_en": "Andorra",
    "name_es": "Andorra",
    "dial_code": "+376",
    "code": "AD"
  },
  {
    "name_en": "Angola",
    "name_es": "Angola",
    "dial_code": "+244",
    "code": "AO"
  },
  {
    "name_en": "Anguilla",
    "name_es": "Anguilla",
    "dial_code": "+1 264",
    "code": "AI"
  },
  {
    "name_en": "Antarctica",
    "name_es": "Antartida",
    "dial_code": "+672",
    "code": "AQ"
  },
  {
    "name_en": "Antigua and Barbuda",
    "name_es": "Antigua y Barbuda",
    "dial_code": "+1268",
    "code": "AG"
  },
  {
    "name_en": "Argentina",
    "name_es": "Argentina",
    "dial_code": "+54",
    "code": "AR"
  },
  {
    "name_en": "Armenia",
    "name_es": "Armenia",
    "dial_code": "+374",
    "code": "AM"
  },
  {
    "name_en": "Aruba",
    "name_es": "Aruba",
    "dial_code": "+297",
    "code": "AW"
  },
  {
    "name_en": "Australia",
    "name_es": "Australia",
    "dial_code": "+61",
    "code": "AU"
  },
  {
    "name_en": "Austria",
    "name_es": "Austria",
    "dial_code": "+43",
    "code": "AT"
  },
  {
    "name_en": "Azerbaijan",
    "name_es": "Azerbaiyan",
    "dial_code": "+994",
    "code": "AZ"
  },
  {
    "name_en": "Bahamas",
    "name_es": "Bahamas",
    "dial_code": "+1 242",
    "code": "BS"
  },
  {
    "name_en": "Bahrain",
    "name_es": "Barein",
    "dial_code": "+973",
    "code": "BH"
  },
  {
    "name_en": "Bangladesh",
    "name_es": "Banglades",
    "dial_code": "+880",
    "code": "BD"
  },
  {
    "name_en": "Barbados",
    "name_es": "Barbados",
    "dial_code": "+1 246",
    "code": "BB"
  },
  {
    "name_en": "Belarus",
    "name_es": "Bielorrusia",
    "dial_code": "+375",
    "code": "BY"
  },
  {
    "name_en": "Belgium",
    "name_es": "Belgica",
    "dial_code": "+32",
    "code": "BE"
  },
  {
    "name_en": "Belize",
    "name_es": "Belice",
    "dial_code": "+501",
    "code": "BZ"
  },
  {
    "name_en": "Benin",
    "name_es": "Benin",
    "dial_code": "+229",
    "code": "BJ"
  },
  {
    "name_en": "Bermuda",
    "name_es": "Bermudas",
    "dial_code": "+1 441",
    "code": "BM"
  },
  {
    "name_en": "Bhutan",
    "name_es": "Butan",
    "dial_code": "+975",
    "code": "BT"
  },
  {
    "name_en": "Bolivia",
    "name_es": "Bolivia",
    "dial_code": "+591",
    "code": "BO"
  },
  {
    "name_en": "Bosnia and Herzegovina",
    "name_es": "Bosnia-Herzegovina",
    "dial_code": "+387",
    "code": "BA"
  },
  {
    "name_en": "Botswana",
    "name_es": "Botsuana",
    "dial_code": "+267",
    "code": "BW"
  },
  {
    "name_en": "Brazil",
    "name_es": "Brasil",
    "dial_code": "+55",
    "code": "BR"
  },
  {
    "name_en": "British Indian Ocean Territory",
    "name_es": "Territorio Británico del Océano Índico",
    "dial_code": "+246",
    "code": "IO"
  },
  {
    "name_en": "Brunei Darussalam",
    "name_es": "Brunei",
    "dial_code": "+673",
    "code": "BN"
  },
  {
    "name_en": "Bulgaria",
    "name_es": "Bulgaria",
    "dial_code": "+359",
    "code": "BG"
  },
  {
    "name_en": "Burkina Faso",
    "name_es": "Burkina Faso",
    "dial_code": "+226",
    "code": "BF"
  },
  {
    "name_en": "Burundi",
    "name_es": "Burundi",
    "dial_code": "+257",
    "code": "BI"
  },
  {
    "name_en": "Cambodia",
    "name_es": "Camboya",
    "dial_code": "+855",
    "code": "KH"
  },
  {
    "name_en": "Cameroon",
    "name_es": "Camerun",
    "dial_code": "+237",
    "code": "CM"
  },
  {
    "name_en": "Canada",
    "name_es": "Canada",
    "dial_code": "+1",
    "code": "CA"
  },
  {
    "name_en": "Cape Verde",
    "name_es": "Cabo Verde",
    "dial_code": "+238",
    "code": "CV"
  },
  {
    "name_en": "Cayman Islands",
    "name_es": "Islas Caiman",
    "dial_code": "+ 345",
    "code": "KY"
  },
  {
    "name_en": "Central African Republic",
    "name_es": "Republica Centroafricana",
    "dial_code": "+236",
    "code": "CF"
  },
  {
    "name_en": "Chad",
    "name_es": "Chad",
    "dial_code": "+235",
    "code": "TD"
  },
  {
    "name_en": "Chile",
    "name_es": "Chile",
    "dial_code": "+56",
    "code": "CL"
  },
  {
    "name_en": "China",
    "name_es": "China",
    "dial_code": "+86",
    "code": "CN"
  },
  {
    "name_en": "Christmas Island",
    "name_es": "Isla de Navidad",
    "dial_code": "+61",
    "code": "CX"
  },
  {
    "name_en": "Cocos (Keeling) Islands",
    "name_es": "Islas Cocos",
    "dial_code": "+61",
    "code": "CC"
  },
  {
    "name_en": "Colombia",
    "name_es": "Colombia",
    "dial_code": "+57",
    "code": "CO"
  },
  {
    "name_en": "Comoros",
    "name_es": "Comoras",
    "dial_code": "+269",
    "code": "KM"
  },
  {
    "name_en": "Congo",
    "name_es": "Congo",
    "dial_code": "+242",
    "code": "CG"
  },
  {
    "name_en": "Congo, The Democratic Republic of the",
    "name_es": "República Democrática del Congo",
    "dial_code": "+243",
    "code": "CD"
  },
  {
    "name_en": "Cook Islands",
    "name_es": "Islas Cook",
    "dial_code": "+682",
    "code": "CK"
  },
  {
    "name_en": "Costa Rica",
    "name_es": "Costa Rica",
    "dial_code": "+506",
    "code": "CR"
  },
  {
    "name_en": "Croatia",
    "name_es": "Croacia",
    "dial_code": "+385",
    "code": "HR"
  },
  {
    "name_en": "Cuba",
    "name_es": "Cuba",
    "dial_code": "+53",
    "code": "CU"
  },
  {
    "name_en": "Cyprus",
    "name_es": "Chipre",
    "dial_code": "+537",
    "code": "CY"
  },
  {
    "name_en": "Czech Republic",
    "name_es": "Republica Checa ",
    "dial_code": "+420",
    "code": "CZ"
  },
  {
    "name_en": "Denmark",
    "name_es": "Dinamarca",
    "dial_code": "+45",
    "code": "DK"
  },
  {
    "name_en": "Dominica",
    "name_es": "Dominica",
    "dial_code": "+1 767",
    "code": "DM"
  },
  {
    "name_en": "Dominican Republic",
    "name_es": "Republica Dominicana",
    "dial_code": "+1 849",
    "code": "DO"
  },
  {
    "name_en": "Ecuador",
    "name_es": "Ecuador",
    "dial_code": "+593",
    "code": "EC"
  },
  {
    "name_en": "Egypt",
    "name_es": "Egipto",
    "dial_code": "+20",
    "code": "EG"
  },
  {
    "name_en": "El Salvador",
    "name_es": "El Salvador",
    "dial_code": "+503",
    "code": "SV"
  },
  {
    "name_en": "Equatorial Guinea",
    "name_es": "Guinea Ecuatorial",
    "dial_code": "+240",
    "code": "GQ"
  },
  {
    "name_en": "Eritrea",
    "name_es": "Eritrea",
    "dial_code": "+291",
    "code": "ER"
  },
  {
    "name_en": "Estonia",
    "name_es": "Estonia",
    "dial_code": "+372",
    "code": "EE"
  },
  {
    "name_en": "Ethiopia",
    "name_es": "Etiopia",
    "dial_code": "+251",
    "code": "ET"
  },
  {
    "name_en": "Falkland Islands (Malvinas)",
    "name_es": "Islas Malvinas",
    "dial_code": "+500",
    "code": "FK"
  },
  {
    "name_en": "Faroe Islands",
    "name_es": "Islas Feroe",
    "dial_code": "+298",
    "code": "FO"
  },
  {
    "name_en": "Fiji",
    "name_es": "Fiyi",
    "dial_code": "+679",
    "code": "FJ"
  },
  {
    "name_en": "Finland",
    "name_es": "Finlandia",
    "dial_code": "+358",
    "code": "FI"
  },
  {
    "name_en": "France",
    "name_es": "Francia",
    "dial_code": "+33",
    "code": "FR"
  },
  {
    "name_en": "French Guiana",
    "name_es": "Guayana Francesa",
    "dial_code": "+594",
    "code": "GF"
  },
  {
    "name_en": "French Polynesia",
    "name_es": "Polinesia Francesa",
    "dial_code": "+689",
    "code": "PF"
  },
  {
    "name_en": "Gabon",
    "name_es": "Gabon",
    "dial_code": "+241",
    "code": "GA"
  },
  {
    "name_en": "Gambia",
    "name_es": "Gambia",
    "dial_code": "+220",
    "code": "GM"
  },
  {
    "name_en": "Georgia",
    "name_es": "Georgia",
    "dial_code": "+995",
    "code": "GE"
  },
  {
    "name_en": "Germany",
    "name_es": "Alemania",
    "dial_code": "+49",
    "code": "DE"
  },
  {
    "name_en": "Ghana",
    "name_es": "Ghana",
    "dial_code": "+233",
    "code": "GH"
  },
  {
    "name_en": "Gibraltar",
    "name_es": "Gibraltar",
    "dial_code": "+350",
    "code": "GI"
  },
  {
    "name_en": "Greece",
    "name_es": "Grecia",
    "dial_code": "+30",
    "code": "GR"
  },
  {
    "name_en": "Greenland",
    "name_es": "Groenlandia",
    "dial_code": "+299",
    "code": "GL"
  },
  {
    "name_en": "Grenada",
    "name_es": "Granada",
    "dial_code": "+1 473",
    "code": "GD"
  },
  {
    "name_en": "Guadeloupe",
    "name_es": "Guadalupe",
    "dial_code": "+590",
    "code": "GP"
  },
  {
    "name_en": "Guam",
    "name_es": "Guam",
    "dial_code": "+1 671",
    "code": "GU"
  },
  {
    "name_en": "Guatemala",
    "name_es": "Guatemala",
    "dial_code": "+502",
    "code": "GT"
  },
  {
    "name_en": "Guernsey",
    "name_es": "Guernsey",
    "dial_code": "+44",
    "code": "GG"
  },
  {
    "name_en": "Guinea",
    "name_es": "Guinea",
    "dial_code": "+224",
    "code": "GN"
  },
  {
    "name_en": "Guinea-Bissau",
    "name_es": "Guinea-Bisau",
    "dial_code": "+245",
    "code": "GW"
  },
  {
    "name_en": "Guyana",
    "name_es": "Guyana",
    "dial_code": "+595",
    "code": "GY"
  },
  {
    "name_en": "Haiti",
    "name_es": "Haiti",
    "dial_code": "+509",
    "code": "HT"
  },
  {
    "name_en": "Holy See (Vatican City State)",
    "name_es": "Ciudad del Vaticano",
    "dial_code": "+379",
    "code": "VA"
  },
  {
    "name_en": "Honduras",
    "name_es": "Honduras",
    "dial_code": "+504",
    "code": "HN"
  },
  {
    "name_en": "Hong Kong",
    "name_es": "Hong Kong",
    "dial_code": "+852",
    "code": "HK"
  },
  {
    "name_en": "Hungary",
    "name_es": "Hungria",
    "dial_code": "+36",
    "code": "HU"
  },
  {
    "name_en": "Iceland",
    "name_es": "Islandia",
    "dial_code": "+354",
    "code": "IS"
  },
  {
    "name_en": "India",
    "name_es": "India",
    "dial_code": "+91",
    "code": "IN"
  },
  {
    "name_en": "Indonesia",
    "name_es": "Indonesia",
    "dial_code": "+62",
    "code": "ID"
  },
  {
    "name_en": "Iran, Islamic Republic of",
    "name_es": "Iran",
    "dial_code": "+98",
    "code": "IR"
  },
  {
    "name_en": "Iraq",
    "name_es": "Iraq",
    "dial_code": "+964",
    "code": "IQ"
  },
  {
    "name_en": "Ireland",
    "name_es": "Irlanda",
    "dial_code": "+353",
    "code": "IE"
  },
  {
    "name_en": "Isle of Man",
    "name_es": "Isla de Man",
    "dial_code": "+44",
    "code": "IM"
  },
  {
    "name_en": "Israel",
    "name_es": "Israel",
    "dial_code": "+972",
    "code": "IL"
  },
  {
    "name_en": "Italy",
    "name_es": "Italia",
    "dial_code": "+39",
    "code": "IT"
  },
  {
    "name_en": "Jamaica",
    "name_es": "Jamaica",
    "dial_code": "+1 876",
    "code": "JM"
  },
  {
    "name_en": "Japan",
    "name_es": "Japon",
    "dial_code": "+81",
    "code": "JP"
  },
  {
    "name_en": "Jersey",
    "name_es": "Jersey",
    "dial_code": "+44",
    "code": "JE"
  },
  {
    "name_en": "Jordan",
    "name_es": "Jordania",
    "dial_code": "+962",
    "code": "JO"
  },
  {
    "name_en": "Kazakhstan",
    "name_es": "Kazajistan",
    "dial_code": "+7 7",
    "code": "KZ"
  },
  {
    "name_en": "Kenya",
    "name_es": "Kenia",
    "dial_code": "+254",
    "code": "KE"
  },
  {
    "name_en": "Kiribati",
    "name_es": "Kiribati",
    "dial_code": "+686",
    "code": "KI"
  },
  {
    "name_en": "Korea, Democratic People's Republic of",
    "name_es": "Corea del Norte",
    "dial_code": "+850",
    "code": "KP"
  },
  {
    "name_en": "Korea, Republic of",
    "name_es": "Corea del Sur",
    "dial_code": "+82",
    "code": "KR"
  },
  {
    "name_en": "Kuwait",
    "name_es": "Kuwait",
    "dial_code": "+965",
    "code": "KW"
  },
  {
    "name_en": "Kyrgyzstan",
    "name_es": "Kirguistan",
    "dial_code": "+996",
    "code": "KG"
  },
  {
    "name_en": "Lao People's Democratic Republic",
    "name_es": "Laos",
    "dial_code": "+856",
    "code": "LA"
  },
  {
    "name_en": "Latvia",
    "name_es": "Letonia",
    "dial_code": "+371",
    "code": "LV"
  },
  {
    "name_en": "Lebanon",
    "name_es": "Libano",
    "dial_code": "+961",
    "code": "LB"
  },
  {
    "name_en": "Lesotho",
    "name_es": "Lesoto",
    "dial_code": "+266",
    "code": "LS"
  },
  {
    "name_en": "Liberia",
    "name_es": "Liberia",
    "dial_code": "+231",
    "code": "LR"
  },
  {
    "name_en": "Libyan Arab Jamahiriya",
    "name_es": "Libia",
    "dial_code": "+218",
    "code": "LY"
  },
  {
    "name_en": "Liechtenstein",
    "name_es": "Liechtenstein",
    "dial_code": "+423",
    "code": "LI"
  },
  {
    "name_en": "Lithuania",
    "name_es": "Lituania",
    "dial_code": "+370",
    "code": "LT"
  },
  {
    "name_en": "Luxembourg",
    "name_es": "Luxemburgo",
    "dial_code": "+352",
    "code": "LU"
  },
  {
    "name_en": "Macao",
    "name_es": "Macao",
    "dial_code": "+853",
    "code": "MO"
  },
  {
    "name_en": "Macedonia, The Former Yugoslav Republic of",
    "name_es": "Republica de Macedonia",
    "dial_code": "+389",
    "code": "MK"
  },
  {
    "name_en": "Madagascar",
    "name_es": "Madagascar",
    "dial_code": "+261",
    "code": "MG"
  },
  {
    "name_en": "Malawi",
    "name_es": "Malaui",
    "dial_code": "+265",
    "code": "MW"
  },
  {
    "name_en": "Malaysia",
    "name_es": "Malasia",
    "dial_code": "+60",
    "code": "MY"
  },
  {
    "name_en": "Maldives",
    "name_es": "Maldivas",
    "dial_code": "+960",
    "code": "MV"
  },
  {
    "name_en": "Mali",
    "name_es": "Mali",
    "dial_code": "+223",
    "code": "ML"
  },
  {
    "name_en": "Malta",
    "name_es": "Malta",
    "dial_code": "+356",
    "code": "MT"
  },
  {
    "name_en": "Marshall Islands",
    "name_es": "Islas Marshall",
    "dial_code": "+692",
    "code": "MH"
  },
  {
    "name_en": "Martinique",
    "name_es": "Martinica",
    "dial_code": "+596",
    "code": "MQ"
  },
  {
    "name_en": "Mauritania",
    "name_es": "Mauritania",
    "dial_code": "+222",
    "code": "MR"
  },
  {
    "name_en": "Mauritius",
    "name_es": "Mauricio",
    "dial_code": "+230",
    "code": "MU"
  },
  {
    "name_en": "Mayotte",
    "name_es": "Mayotte",
    "dial_code": "+262",
    "code": "YT"
  },
  {
    "name_en": "Mexico",
    "name_es": "Mexico",
    "dial_code": "+52",
    "code": "MX"
  },
  {
    "name_en": "Micronesia, Federated States of",
    "name_es": "Estados Federados de Micronesia",
    "dial_code": "+691",
    "code": "FM"
  },
  {
    "name_en": "Moldova, Republic of",
    "name_es": "Moldavia",
    "dial_code": "+373",
    "code": "MD"
  },
  {
    "name_en": "Monaco",
    "name_es": "Monaco",
    "dial_code": "+377",
    "code": "MC"
  },
  {
    "name_en": "Mongolia",
    "name_es": "Mongolia",
    "dial_code": "+976",
    "code": "MN"
  },
  {
    "name_en": "Montenegro",
    "name_es": "Montenegro",
    "dial_code": "+382",
    "code": "ME"
  },
  {
    "name_en": "Montserrat",
    "name_es": "Montserrat",
    "dial_code": "+1664",
    "code": "MS"
  },
  {
    "name_en": "Morocco",
    "name_es": "Marruecos",
    "dial_code": "+212",
    "code": "MA"
  },
  {
    "name_en": "Mozambique",
    "name_es": "Mozambique",
    "dial_code": "+258",
    "code": "MZ"
  },
  {
    "name_en": "Myanmar",
    "name_es": "Birmania",
    "dial_code": "+95",
    "code": "MM"
  },
  {
    "name_en": "Namibia",
    "name_es": "Namibia",
    "dial_code": "+264",
    "code": "NA"
  },
  {
    "name_en": "Nauru",
    "name_es": "Nauru",
    "dial_code": "+674",
    "code": "NR"
  },
  {
    "name_en": "Nepal",
    "name_es": "Nepal",
    "dial_code": "+977",
    "code": "NP"
  },
  {
    "name_en": "Netherlands",
    "name_es": "Holanda",
    "dial_code": "+31",
    "code": "NL"
  },
  {
    "name_en": "Netherlands Antilles",
    "name_es": "Antillas Holandesas",
    "dial_code": "+599",
    "code": "AN"
  },
  {
    "name_en": "New Caledonia",
    "name_es": "Nueva Caledonia",
    "dial_code": "+687",
    "code": "NC"
  },
  {
    "name_en": "New Zealand",
    "name_es": "Nueva  Zelanda",
    "dial_code": "+64",
    "code": "NZ"
  },
  {
    "name_en": "Nicaragua",
    "name_es": "Nicaragua",
    "dial_code": "+505",
    "code": "NI"
  },
  {
    "name_en": "Niger",
    "name_es": "Niger",
    "dial_code": "+227",
    "code": "NE"
  },
  {
    "name_en": "Nigeria",
    "name_es": "Nigeria",
    "dial_code": "+234",
    "code": "NG"
  },
  {
    "name_en": "Niue",
    "name_es": "Niue",
    "dial_code": "+683",
    "code": "NU"
  },
  {
    "name_en": "NorfolkIsland",
    "name_es": "IslaNorfolk",
    "dial_code": "+672",
    "code": "NF"
  },
  {
    "name_en": "NorthernMarianaIslands",
    "name_es": "IslasMarianasdelNorte",
    "dial_code": "+1670",
    "code": "MP"
  },
  {
    "name_en": "Norway",
    "name_es": "Noruega",
    "dial_code": "+47",
    "code": "NO"
  },
  {
    "name_en": "Oman",
    "name_es": "Oman",
    "dial_code": "+968",
    "code": "OM"
  },
  {
    "name_en": "Pakistan",
    "name_es": "Pakistan",
    "dial_code": "+92",
    "code": "PK"
  },
  {
    "name_en": "Palau",
    "name_es": "Palaos",
    "dial_code": "+680",
    "code": "PW"
  },
  {
    "name_en": "PalestinianTerritory",
    "name_es": "TerritoriosPalestinos",
    "dial_code": "+970",
    "code": "PS"
  },
  {
    "name_en": "Panama",
    "name_es": "Panama",
    "dial_code": "+507",
    "code": "PA"
  },
  {
    "name_en": "PapuaNewGuinea",
    "name_es": "PapuaNuevaGuinea",
    "dial_code": "+675",
    "code": "PG"
  },
  {
    "name_en": "Paraguay",
    "name_es": "Paraguay",
    "dial_code": "+595",
    "code": "PY"
  },
  {
    "name_en": "Peru",
    "name_es": "Peru",
    "dial_code": "+51",
    "code": "PE"
  },
  {
    "name_en": "Philippines",
    "name_es": "Filipinas",
    "dial_code": "+63",
    "code": "PH"
  },
  {
    "name_en": "Pitcairn",
    "name_es": "Islas Pitcairn",
    "dial_code": "+872",
    "code": "PN"
  },
  {
    "name_en": "Poland",
    "name_es": "Polonia",
    "dial_code": "+48",
    "code": "PL"
  },
  {
    "name_en": "Portugal",
    "name_es": "Portugal",
    "dial_code": "+351",
    "code": "PT"
  },
  {
    "name_en": "Puerto Rico",
    "name_es": "Puerto Rico",
    "dial_code": "+1 939",
    "code": "PR"
  },
  {
    "name_en": "Qatar",
    "name_es": "Qatar",
    "dial_code": "+974",
    "code": "QA"
  },
  {
    "name_en": "Romania",
    "name_es": "Rumania",
    "dial_code": "+40",
    "code": "RO"
  },
  {
    "name_en": "Russia",
    "name_es": "Rusia",
    "dial_code": "+7",
    "code": "RU"
  },
  {
    "name_en": "Rwanda",
    "name_es": "Ruanda",
    "dial_code": "+250",
    "code": "RW"
  },
  {
    "name_en": "Réunion",
    "name_es": "Reunion",
    "dial_code": "+262",
    "code": "RE"
  },
  {
    "name_en": "Saint Barthélemy",
    "name_es": "San Bartolome",
    "dial_code": "+590",
    "code": "BL"
  },
  {
    "name_en": "Saint Helena, Ascension and Tristan Da Cunha",
    "name_es": "Santa Elena, Ascensión y Tristán de Acuña",
    "dial_code": "+290",
    "code": "SH"
  },
  {
    "name_en": "Saint Kitts and Nevis",
    "name_es": "San Cristóbal y Nieves",
    "dial_code": "+1 869",
    "code": "KN"
  },
  {
    "name_en": "Saint Lucia",
    "name_es": "Santa Lucia",
    "dial_code": "+1 758",
    "code": "LC"
  },
  {
    "name_en": "Saint Martin",
    "name_es": "Isla de San Martín",
    "dial_code": "+590",
    "code": "MF"
  },
  {
    "name_en": "Saint Pierre and Miquelon",
    "name_es": "San Pedro y Miquelon",
    "dial_code": "+508",
    "code": "PM"
  },
  {
    "name_en": "Saint Vincent and the Grenadines",
    "name_es": "San Vicente y las Granadinas",
    "dial_code": "+1 784",
    "code": "VC"
  },
  {
    "name_en": "Samoa",
    "name_es": "Samoa",
    "dial_code": "+685",
    "code": "WS"
  },
  {
    "name_en": "San Marino",
    "name_es": "San Marino",
    "dial_code": "+378",
    "code": "SM"
  },
  {
    "name_en": "Saudi Arabia",
    "name_es": "Arabia Saudita",
    "dial_code": "+966",
    "code": "SA"
  },
  {
    "name_en": "Senegal",
    "name_es": "Senegal",
    "dial_code": "+221",
    "code": "SN"
  },
  {
    "name_en": "Serbia",
    "name_es": "Serbia",
    "dial_code": "+381",
    "code": "RS"
  },
  {
    "name_en": "Seychelles",
    "name_es": "Seychelles",
    "dial_code": "+248",
    "code": "SC"
  },
  {
    "name_en": "Sierra Leone",
    "name_es": "Sierra Leona",
    "dial_code": "+232",
    "code": "SL"
  },
  {
    "name_en": "Singapore",
    "name_es": "Singapur",
    "dial_code": "+65",
    "code": "SG"
  },
  {
    "name_en": "Slovakia",
    "name_es": "Eslovaquia",
    "dial_code": "+421",
    "code": "SK"
  },
  {
    "name_en": "Slovenia",
    "name_es": "Eslovenia",
    "dial_code": "+386",
    "code": "SI"
  },
  {
    "name_en": "Solomon Islands",
    "name_es": "Islas Salomón",
    "dial_code": "+677",
    "code": "SB"
  },
  {
    "name_en": "Somalia",
    "name_es": "Somalia",
    "dial_code": "+252",
    "code": "SO"
  },
  {
    "name_en": "South Africa",
    "name_es": "Sudáfrica",
    "dial_code": "+27",
    "code": "ZA"
  },
  {
    "name_en": "South Georgia and the South Sandwich Islands",
    "name_es": "Islas Georgias del Sur y Sandwich del Sur",
    "dial_code": "+500",
    "code": "GS"
  },
  {
    "name_en": "Sri Lanka",
    "name_es": "Sri Lanka",
    "dial_code": "+94",
    "code": "LK"
  },
  {
    "name_en": "Sudan",
    "name_es": "Sudan",
    "dial_code": "+249",
    "code": "SD"
  },
  {
    "name_en": "Suriname",
    "name_es": "Surinam ",
    "dial_code": "+597",
    "code": "SR"
  },
  {
    "name_en": "Svalbard and Jan Mayen",
    "name_es": "Svalbard y Jan Mayen",
    "dial_code": "+47",
    "code": "SJ"
  },
  {
    "name_en": "Swaziland",
    "name_es": "Suazilandia",
    "dial_code": "+268",
    "code": "SZ"
  },
  {
    "name_en": "Sweden",
    "name_es": "Suecia",
    "dial_code": "+46",
    "code": "SE"
  },
  {
    "name_en": "Switzerland",
    "name_es": "Suiza",
    "dial_code": "+41",
    "code": "CH"
  },
  {
    "name_en": "Syrian Arab Republic",
    "name_es": "Siria",
    "dial_code": "+963",
    "code": "SY"
  },
  {
    "name_en": "Taiwan, Province of China",
    "name_es": "Taiwan",
    "dial_code": "+886",
    "code": "TW"
  },
  {
    "name_en": "Tajikistan",
    "name_es": "Tayikistan",
    "dial_code": "+992",
    "code": "TJ"
  },
  {
    "name_en": "Tanzania, United Republic of",
    "name_es": "Tanzania",
    "dial_code": "+255",
    "code": "TZ"
  },
  {
    "name_en": "Thailand",
    "name_es": "Tailandia",
    "dial_code": "+66",
    "code": "TH"
  },
  {
    "name_en": "Timor-Leste",
    "name_es": "Timor Oriental",
    "dial_code": "+670",
    "code": "TL"
  },
  {
    "name_en": "Togo",
    "name_es": "Togo",
    "dial_code": "+228",
    "code": "TG"
  },
  {
    "name_en": "Tokelau",
    "name_es": "Tokelau",
    "dial_code": "+690",
    "code": "TK"
  },
  {
    "name_en": "Tonga",
    "name_es": "Tonga",
    "dial_code": "+676",
    "code": "TO"
  },
  {
    "name_en": "Trinidad and Tobago",
    "name_es": "Trinidad y Tobago",
    "dial_code": "+1 868",
    "code": "TT"
  },
  {
    "name_en": "Tunisia",
    "name_es": "Tunez",
    "dial_code": "+216",
    "code": "TN"
  },
  {
    "name_en": "Turkey",
    "name_es": "Turquia",
    "dial_code": "+90",
    "code": "TR"
  },
  {
    "name_en": "Turkmenistan",
    "name_es": "Turkmenistan",
    "dial_code": "+993",
    "code": "TM"
  },
  {
    "name_en": "Turks and Caicos Islands",
    "name_es": "Islas Turcas y Caicos",
    "dial_code": "+1 649",
    "code": "TC"
  },
  {
    "name_en": "Tuvalu",
    "name_es": "Tuvalu",
    "dial_code": "+688",
    "code": "TV"
  },
  {
    "name_en": "Uganda",
    "name_es": "Uganda",
    "dial_code": "+256",
    "code": "UG"
  },
  {
    "name_en": "Ukraine",
    "name_es": "Ucrania",
    "dial_code": "+380",
    "code": "UA"
  },
  {
    "name_en": "United States",
    "name_es": "Estados Unidos",
    "dial_code": "+1",
    "code": "US"
  },
  {
    "name_en": "United Arab Emirates",
    "name_es": "Emiratos Arabes Unidos",
    "dial_code": "+971",
    "code": "AE"
  },
  {
    "name_en": "United Kingdom",
    "name_es": "Reino Unido",
    "dial_code": "+44",
    "code": "GB"
  },
  {
    "name_en": "Uruguay",
    "name_es": "Uruguay",
    "dial_code": "+598",
    "code": "UY"
  },
  {
    "name_en": "Uzbekistan",
    "name_es": "Uzbekistan",
    "dial_code": "+998",
    "code": "UZ"
  },
  {
    "name_en": "Vanuatu",
    "name_es": "Vanuatu",
    "dial_code": "+678",
    "code": "VU"
  },
  {
    "name_en": "Venezuela, Bolivarian Republic of",
    "name_es": "Venezuela",
    "dial_code": "+58",
    "code": "VE"
  },
  {
    "name_en": "Viet Nam",
    "name_es": "Viet Nam",
    "dial_code": "+84",
    "code": "VN"
  },
  {
    "name_en": "Virgin Islands, British",
    "name_es": "Islas Vírgenes Británicas",
    "dial_code": "+1 284",
    "code": "VG"
  },
  {
    "name_en": "Virgin Islands, U.S.",
    "name_es": "Islas Vírgenes de los Estados Unidos",
    "dial_code": "+1 340",
    "code": "VI"
  },
  {
    "name_en": "Wallis and Futuna",
    "name_es": "Wallis y Futuna",
    "dial_code": "+681",
    "code": "WF"
  },
  {
    "name_en": "Yemen",
    "name_es": "Yemen",
    "dial_code": "+967",
    "code": "YE"
  },
  {
    "name_en": "Zambia",
    "name_es": "Zambia",
    "dial_code": "+260",
    "code": "ZM"
  },
  {
    "name_en": "Åland Islands",
    "name_es": "Åland",
    "dial_code": "+358",
    "code": "AX"
  }
];
export const provinces = {
  "es": [
    {
      "name": "A Coruña",
      "code": "ES-C",
      "subdivision": "Province"
    },
    {
      "name": "Albacete",
      "code": "ES-AB",
      "subdivision": "Province"
    },
    {
      "name": "Alicante",
      "code": "ES-A",
      "subdivision": "Province"
    },
    {
      "name": "Almería",
      "code": "ES-AL",
      "subdivision": "Province"
    },
    {
      "name": "Asturias",
      "code": "ES-O",
      "subdivision": "Province"
    },
    {
      "name": "Badajoz",
      "code": "ES-BA",
      "subdivision": "Province"
    },
    {
      "name": "Balears",
      "code": "ES-PM",
      "subdivision": "Province"
    },
    {
      "name": "Barcelona",
      "code": "ES-B",
      "subdivision": "Province"
    },
    {
      "name": "Burgos",
      "code": "ES-BU",
      "subdivision": "Province"
    },
    {
      "name": "Cantabria",
      "code": "ES-S",
      "subdivision": "Province"
    },
    {
      "name": "Castellón",
      "code": "ES-CS",
      "subdivision": "Province"
    },
    {
      "name": "Ciudad Real",
      "code": "ES-CR",
      "subdivision": "Province"
    },
    {
      "name": "Cuenca",
      "code": "ES-CU",
      "subdivision": "Province"
    },
    {
      "name": "Cáceres",
      "code": "ES-CC",
      "subdivision": "Province"
    },
    {
      "name": "Cádiz",
      "code": "ES-CA",
      "subdivision": "Province"
    },
    {
      "name": "Córdoba",
      "code": "ES-CO",
      "subdivision": "Province"
    },
    {
      "name": "Girona",
      "code": "ES-GI",
      "subdivision": "Province"
    },
    {
      "name": "Granada",
      "code": "ES-GR",
      "subdivision": "Province"
    },
    {
      "name": "Guadalajara",
      "code": "ES-GU",
      "subdivision": "Province"
    },
    {
      "name": "Guipúzcoa",
      "code": "ES-SS",
      "subdivision": "Province"
    },
    {
      "name": "Huelva",
      "code": "ES-H",
      "subdivision": "Province"
    },
    {
      "name": "Huesca",
      "code": "ES-HU",
      "subdivision": "Province"
    },
    {
      "name": "Jaén",
      "code": "ES-J",
      "subdivision": "Province"
    },
    {
      "name": "La Rioja",
      "code": "ES-LO",
      "subdivision": "Province"
    },
    {
      "name": "Las Palmas",
      "code": "ES-GC",
      "subdivision": "Province"
    },
    {
      "name": "León",
      "code": "ES-LE",
      "subdivision": "Province"
    },
    {
      "name": "Lleida",
      "code": "ES-L",
      "subdivision": "Province"
    },
    {
      "name": "Lugo",
      "code": "ES-LU",
      "subdivision": "Province"
    },
    {
      "name": "Madrid",
      "code": "ES-M",
      "subdivision": "Province"
    },
    {
      "name": "Murcia",
      "code": "ES-MU",
      "subdivision": "Province"
    },
    {
      "name": "Málaga",
      "code": "ES-MA",
      "subdivision": "Province"
    },
    {
      "name": "Navarra",
      "code": "ES-NA",
      "subdivision": "Province"
    },
    {
      "name": "Ourense",
      "code": "ES-OR",
      "subdivision": "Province"
    },
    {
      "name": "Palencia",
      "code": "ES-P",
      "subdivision": "Province"
    },
    {
      "name": "Pontevedra",
      "code": "ES-PO",
      "subdivision": "Province"
    },
    {
      "name": "Salamanca",
      "code": "ES-SA",
      "subdivision": "Province"
    },
    {
      "name": "Santa Cruz de Tenerife",
      "code": "ES-TF",
      "subdivision": "Province"
    },
    {
      "name": "Segovia",
      "code": "ES-SG",
      "subdivision": "Province"
    },
    {
      "name": "Sevilla",
      "code": "ES-SE",
      "subdivision": "Province"
    },
    {
      "name": "Soria",
      "code": "ES-SO",
      "subdivision": "Province"
    },
    {
      "name": "Tarragona",
      "code": "ES-T",
      "subdivision": "Province"
    },
    {
      "name": "Teruel",
      "code": "ES-TE",
      "subdivision": "Province"
    },
    {
      "name": "Toledo",
      "code": "ES-TO",
      "subdivision": "Province"
    },
    {
      "name": "Valencia",
      "code": "ES-V",
      "subdivision": "Province"
    },
    {
      "name": "Valladolid",
      "code": "ES-VA",
      "subdivision": "Province"
    },
    {
      "name": "Vizcaya",
      "code": "ES-BI",
      "subdivision": "Province"
    },
    {
      "name": "Zamora",
      "code": "ES-ZA",
      "subdivision": "Province"
    },
    {
      "name": "Zaragoza",
      "code": "ES-Z",
      "subdivision": "Province"
    },
    {
      "name": "Álava",
      "code": "ES-VI",
      "subdivision": "Province"
    },
    {
      "name": "Ávila",
      "code": "ES-AV",
      "subdivision": "Province"
    },
    {
      "name": "Ceuta",
      "code": "ES-CE",
      "subdivision": "autonomous city in North Africa"
    },
    {
      "name": "Melilla",
      "code": "ES-ML",
      "subdivision": "autonomous city in North Africa"
    },
    {
      "name": "Andalucía",
      "code": "ES-AN",
      "subdivision": "autonomous community"
    },
    {
      "name": "Aragón",
      "code": "ES-AR",
      "subdivision": "autonomous community"
    },
    {
      "name": "Asturias, Principado de",
      "code": "ES-AS",
      "subdivision": "autonomous community"
    },
    {
      "name": "Canarias",
      "code": "ES-CN",
      "subdivision": "autonomous community"
    },
    {
      "name": "Cantabria",
      "code": "ES-CB",
      "subdivision": "autonomous community"
    },
    {
      "name": "Castilla y León",
      "code": "ES-CL",
      "subdivision": "autonomous community"
    },
    {
      "name": "Castilla-La Mancha",
      "code": "ES-CM",
      "subdivision": "autonomous community"
    },
    {
      "name": "Catalunya",
      "code": "ES-CT",
      "subdivision": "autonomous community"
    },
    {
      "name": "Extremadura",
      "code": "ES-EX",
      "subdivision": "autonomous community"
    },
    {
      "name": "Galicia",
      "code": "ES-GA",
      "subdivision": "autonomous community"
    },
    {
      "name": "Illes Balears",
      "code": "ES-IB",
      "subdivision": "autonomous community"
    },
    {
      "name": "La Rioja",
      "code": "ES-RI",
      "subdivision": "autonomous community"
    },
    {
      "name": "Comunidad de Madrid",
      "code": "ES-MD",
      "subdivision": "autonomous community"
    },
    {
      "name": "Región de Murcia",
      "code": "ES-MC",
      "subdivision": "autonomous community"
    },
    {
      "name": "Navarra, Comunidad Foral de",
      "code": "ES-NC",
      "subdivision": "autonomous community"
    },
    {
      "name": "País Vasco",
      "code": "ES-PV",
      "subdivision": "autonomous community"
    },
    {
      "name": "Comunidad Valenciana",
      "code": "ES-VC",
      "subdivision": "autonomous community"
    }
  ],

  "uk": [
    {
      "name": "Barking and Dagenham",
      "code": "GB-BDG",
      "subdivision": "London borough"
    },
    {
      "name": "Barnet",
      "code": "GB-BNE",
      "subdivision": "London borough"
    },
    {
      "name": "Bexley",
      "code": "GB-BEX",
      "subdivision": "London borough"
    },
    {
      "name": "Brent",
      "code": "GB-BEN",
      "subdivision": "London borough"
    },
    {
      "name": "Bromley",
      "code": "GB-BRY",
      "subdivision": "London borough"
    },
    {
      "name": "Camden",
      "code": "GB-CMD",
      "subdivision": "London borough"
    },
    {
      "name": "Croydon",
      "code": "GB-CRY",
      "subdivision": "London borough"
    },
    {
      "name": "Ealing",
      "code": "GB-EAL",
      "subdivision": "London borough"
    },
    {
      "name": "Enfield",
      "code": "GB-ENF",
      "subdivision": "London borough"
    },
    {
      "name": "Greenwich",
      "code": "GB-GRE",
      "subdivision": "London borough"
    },
    {
      "name": "Hackney",
      "code": "GB-HCK",
      "subdivision": "London borough"
    },
    {
      "name": "Hammersmith and Fulham",
      "code": "GB-HMF",
      "subdivision": "London borough"
    },
    {
      "name": "Haringey",
      "code": "GB-HRY",
      "subdivision": "London borough"
    },
    {
      "name": "Harrow",
      "code": "GB-HRW",
      "subdivision": "London borough"
    },
    {
      "name": "Havering",
      "code": "GB-HAV",
      "subdivision": "London borough"
    },
    {
      "name": "Hillingdon",
      "code": "GB-HIL",
      "subdivision": "London borough"
    },
    {
      "name": "Hounslow",
      "code": "GB-HNS",
      "subdivision": "London borough"
    },
    {
      "name": "Islington",
      "code": "GB-ISL",
      "subdivision": "London borough"
    },
    {
      "name": "Kensington and Chelsea",
      "code": "GB-KEC",
      "subdivision": "London borough"
    },
    {
      "name": "Kingston upon Thames",
      "code": "GB-KTT",
      "subdivision": "London borough"
    },
    {
      "name": "Lambeth",
      "code": "GB-LBH",
      "subdivision": "London borough"
    },
    {
      "name": "Lewisham",
      "code": "GB-LEW",
      "subdivision": "London borough"
    },
    {
      "name": "Merton",
      "code": "GB-MRT",
      "subdivision": "London borough"
    },
    {
      "name": "Newham",
      "code": "GB-NWM",
      "subdivision": "London borough"
    },
    {
      "name": "Redbridge",
      "code": "GB-RDB",
      "subdivision": "London borough"
    },
    {
      "name": "Richmond upon Thames",
      "code": "GB-RIC",
      "subdivision": "London borough"
    },
    {
      "name": "Southwark",
      "code": "GB-SWK",
      "subdivision": "London borough"
    },
    {
      "name": "Sutton",
      "code": "GB-STN",
      "subdivision": "London borough"
    },
    {
      "name": "Tower Hamlets",
      "code": "GB-TWH",
      "subdivision": "London borough"
    },
    {
      "name": "Waltham Forest",
      "code": "GB-WFT",
      "subdivision": "London borough"
    },
    {
      "name": "Wandsworth",
      "code": "GB-WND",
      "subdivision": "London borough"
    },
    {
      "name": "Westminster",
      "code": "GB-WSM",
      "subdivision": "London borough"
    },
    {
      "name": "England and Wales",
      "code": "GB-EAW",
      "subdivision": "Nation"
    },
    {
      "name": "Great Britain",
      "code": "GB-GBN",
      "subdivision": "Nation"
    },
    {
      "name": "United Kingdom",
      "code": "GB-UKM",
      "subdivision": "Nation"
    },
    {
      "name": "London, City of",
      "code": "GB-LND",
      "subdivision": "city corporation"
    },
    {
      "name": "Aberdeen City",
      "code": "GB-ABE",
      "subdivision": "council area"
    },
    {
      "name": "Aberdeenshire",
      "code": "GB-ABD",
      "subdivision": "council area"
    },
    {
      "name": "Angus",
      "code": "GB-ANS",
      "subdivision": "council area"
    },
    {
      "name": "Argyll and Bute",
      "code": "GB-AGB",
      "subdivision": "council area"
    },
    {
      "name": "Clackmannanshire",
      "code": "GB-CLK",
      "subdivision": "council area"
    },
    {
      "name": "Dumfries and Galloway",
      "code": "GB-DGY",
      "subdivision": "council area"
    },
    {
      "name": "Dundee City",
      "code": "GB-DND",
      "subdivision": "council area"
    },
    {
      "name": "East Ayrshire",
      "code": "GB-EAY",
      "subdivision": "council area"
    },
    {
      "name": "East Dunbartonshire",
      "code": "GB-EDU",
      "subdivision": "council area"
    },
    {
      "name": "East Lothian",
      "code": "GB-ELN",
      "subdivision": "council area"
    },
    {
      "name": "East Renfrewshire",
      "code": "GB-ERW",
      "subdivision": "council area"
    },
    {
      "name": "Edinburgh, City of",
      "code": "GB-EDH",
      "subdivision": "council area"
    },
    {
      "name": "Eilean Siar",
      "code": "GB-ELS",
      "subdivision": "council area"
    },
    {
      "name": "Falkirk",
      "code": "GB-FAL",
      "subdivision": "council area"
    },
    {
      "name": "Fife",
      "code": "GB-FIF",
      "subdivision": "council area"
    },
    {
      "name": "Glasgow City",
      "code": "GB-GLG",
      "subdivision": "council area"
    },
    {
      "name": "Highland",
      "code": "GB-HLD",
      "subdivision": "council area"
    },
    {
      "name": "Inverclyde",
      "code": "GB-IVC",
      "subdivision": "council area"
    },
    {
      "name": "Midlothian",
      "code": "GB-MLN",
      "subdivision": "council area"
    },
    {
      "name": "Moray",
      "code": "GB-MRY",
      "subdivision": "council area"
    },
    {
      "name": "North Ayrshire",
      "code": "GB-NAY",
      "subdivision": "council area"
    },
    {
      "name": "North Lanarkshire",
      "code": "GB-NLK",
      "subdivision": "council area"
    },
    {
      "name": "Orkney Islands",
      "code": "GB-ORK",
      "subdivision": "council area"
    },
    {
      "name": "Perth and Kinross",
      "code": "GB-PKN",
      "subdivision": "council area"
    },
    {
      "name": "Renfrewshire",
      "code": "GB-RFW",
      "subdivision": "council area"
    },
    {
      "name": "Scottish Borders, The",
      "code": "GB-SCB",
      "subdivision": "council area"
    },
    {
      "name": "Shetland Islands",
      "code": "GB-ZET",
      "subdivision": "council area"
    },
    {
      "name": "South Ayrshire",
      "code": "GB-SAY",
      "subdivision": "council area"
    },
    {
      "name": "South Lanarkshire",
      "code": "GB-SLK",
      "subdivision": "council area"
    },
    {
      "name": "Stirling",
      "code": "GB-STG",
      "subdivision": "council area"
    },
    {
      "name": "West Dunbartonshire",
      "code": "GB-WDU",
      "subdivision": "council area"
    },
    {
      "name": "West Lothian",
      "code": "GB-WLN",
      "subdivision": "council area"
    },
    {
      "name": "England",
      "code": "GB-ENG",
      "subdivision": "country"
    },
    {
      "name": "Scotland",
      "code": "GB-SCT",
      "subdivision": "country"
    },
    {
      "name": "Wales",
      "code": "GB-WLS",
      "subdivision": "country"
    },
    {
      "name": "Antrim",
      "code": "GB-ANT",
      "subdivision": "district council area"
    },
    {
      "name": "Ards",
      "code": "GB-ARD",
      "subdivision": "district council area"
    },
    {
      "name": "Armagh",
      "code": "GB-ARM",
      "subdivision": "district council area"
    },
    {
      "name": "Ballymena",
      "code": "GB-BLA",
      "subdivision": "district council area"
    },
    {
      "name": "Ballymoney",
      "code": "GB-BLY",
      "subdivision": "district council area"
    },
    {
      "name": "Banbridge",
      "code": "GB-BNB",
      "subdivision": "district council area"
    },
    {
      "name": "Belfast",
      "code": "GB-BFS",
      "subdivision": "district council area"
    },
    {
      "name": "Carrickfergus",
      "code": "GB-CKF",
      "subdivision": "district council area"
    },
    {
      "name": "Castlereagh",
      "code": "GB-CSR",
      "subdivision": "district council area"
    },
    {
      "name": "Coleraine",
      "code": "GB-CLR",
      "subdivision": "district council area"
    },
    {
      "name": "Cookstown",
      "code": "GB-CKT",
      "subdivision": "district council area"
    },
    {
      "name": "Craigavon",
      "code": "GB-CGV",
      "subdivision": "district council area"
    },
    {
      "name": "Derry",
      "code": "GB-DRY",
      "subdivision": "district council area"
    },
    {
      "name": "Down",
      "code": "GB-DOW",
      "subdivision": "district council area"
    },
    {
      "name": "Dungannon and South Tyrone",
      "code": "GB-DGN",
      "subdivision": "district council area"
    },
    {
      "name": "Fermanagh",
      "code": "GB-FER",
      "subdivision": "district council area"
    },
    {
      "name": "Larne",
      "code": "GB-LRN",
      "subdivision": "district council area"
    },
    {
      "name": "Limavady",
      "code": "GB-LMV",
      "subdivision": "district council area"
    },
    {
      "name": "Lisburn",
      "code": "GB-LSB",
      "subdivision": "district council area"
    },
    {
      "name": "Magherafelt",
      "code": "GB-MFT",
      "subdivision": "district council area"
    },
    {
      "name": "Moyle",
      "code": "GB-MYL",
      "subdivision": "district council area"
    },
    {
      "name": "Newry and Mourne District",
      "code": "GB-NYM",
      "subdivision": "district council area"
    },
    {
      "name": "Newtownabbey",
      "code": "GB-NTA",
      "subdivision": "district council area"
    },
    {
      "name": "North Down",
      "code": "GB-NDN",
      "subdivision": "district council area"
    },
    {
      "name": "Omagh",
      "code": "GB-OMH",
      "subdivision": "district council area"
    },
    {
      "name": "Strabane",
      "code": "GB-STB",
      "subdivision": "district council area"
    },
    {
      "name": "Barnsley",
      "code": "GB-BNS",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Birmingham",
      "code": "GB-BIR",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Bolton",
      "code": "GB-BOL",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Bradford",
      "code": "GB-BRD",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Bury",
      "code": "GB-BUR",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Calderdale",
      "code": "GB-CLD",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Coventry",
      "code": "GB-COV",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Doncaster",
      "code": "GB-DNC",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Dudley",
      "code": "GB-DUD",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Gateshead",
      "code": "GB-GAT",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Kirklees",
      "code": "GB-KIR",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Knowsley",
      "code": "GB-KWL",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Leeds",
      "code": "GB-LDS",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Liverpool",
      "code": "GB-LIV",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Manchester",
      "code": "GB-MAN",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Newcastle upon Tyne",
      "code": "GB-NET",
      "subdivision": "metropolitan district"
    },
    {
      "name": "North Tyneside",
      "code": "GB-NTY",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Oldham",
      "code": "GB-OLD",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Rochdale",
      "code": "GB-RCH",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Rotherham",
      "code": "GB-ROT",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Salford",
      "code": "GB-SLF",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Sandwell",
      "code": "GB-SAW",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Sefton",
      "code": "GB-SFT",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Sheffield",
      "code": "GB-SHF",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Solihull",
      "code": "GB-SOL",
      "subdivision": "metropolitan district"
    },
    {
      "name": "South Tyneside",
      "code": "GB-STY",
      "subdivision": "metropolitan district"
    },
    {
      "name": "St. Helens",
      "code": "GB-SHN",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Stockport",
      "code": "GB-SKP",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Sunderland",
      "code": "GB-SND",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Tameside",
      "code": "GB-TAM",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Trafford",
      "code": "GB-TRF",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Wakefield",
      "code": "GB-WKF",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Walsall",
      "code": "GB-WLL",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Wigan",
      "code": "GB-WGN",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Wirral",
      "code": "GB-WRL",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Wolverhampton",
      "code": "GB-WLV",
      "subdivision": "metropolitan district"
    },
    {
      "name": "Northern Ireland",
      "code": "GB-NIR",
      "subdivision": "province"
    },
    {
      "name": "Buckinghamshire",
      "code": "GB-BKM",
      "subdivision": "two-tier county"
    },
    {
      "name": "Cambridgeshire",
      "code": "GB-CAM",
      "subdivision": "two-tier county"
    },
    {
      "name": "Cumbria",
      "code": "GB-CMA",
      "subdivision": "two-tier county"
    },
    {
      "name": "Derbyshire",
      "code": "GB-DBY",
      "subdivision": "two-tier county"
    },
    {
      "name": "Devon",
      "code": "GB-DEV",
      "subdivision": "two-tier county"
    },
    {
      "name": "Dorset",
      "code": "GB-DOR",
      "subdivision": "two-tier county"
    },
    {
      "name": "East Sussex",
      "code": "GB-ESX",
      "subdivision": "two-tier county"
    },
    {
      "name": "Essex",
      "code": "GB-ESS",
      "subdivision": "two-tier county"
    },
    {
      "name": "Gloucestershire",
      "code": "GB-GLS",
      "subdivision": "two-tier county"
    },
    {
      "name": "Hampshire",
      "code": "GB-HAM",
      "subdivision": "two-tier county"
    },
    {
      "name": "Hertfordshire",
      "code": "GB-HRT",
      "subdivision": "two-tier county"
    },
    {
      "name": "Kent",
      "code": "GB-KEN",
      "subdivision": "two-tier county"
    },
    {
      "name": "Lancashire",
      "code": "GB-LAN",
      "subdivision": "two-tier county"
    },
    {
      "name": "Leicestershire",
      "code": "GB-LEC",
      "subdivision": "two-tier county"
    },
    {
      "name": "Lincolnshire",
      "code": "GB-LIN",
      "subdivision": "two-tier county"
    },
    {
      "name": "Norfolk",
      "code": "GB-NFK",
      "subdivision": "two-tier county"
    },
    {
      "name": "North Yorkshire",
      "code": "GB-NYK",
      "subdivision": "two-tier county"
    },
    {
      "name": "Northamptonshire",
      "code": "GB-NTH",
      "subdivision": "two-tier county"
    },
    {
      "name": "Nottinghamshire",
      "code": "GB-NTT",
      "subdivision": "two-tier county"
    },
    {
      "name": "Oxfordshire",
      "code": "GB-OXF",
      "subdivision": "two-tier county"
    },
    {
      "name": "Somerset",
      "code": "GB-SOM",
      "subdivision": "two-tier county"
    },
    {
      "name": "Staffordshire",
      "code": "GB-STS",
      "subdivision": "two-tier county"
    },
    {
      "name": "Suffolk",
      "code": "GB-SFK",
      "subdivision": "two-tier county"
    },
    {
      "name": "Surrey",
      "code": "GB-SRY",
      "subdivision": "two-tier county"
    },
    {
      "name": "Warwickshire",
      "code": "GB-WAR",
      "subdivision": "two-tier county"
    },
    {
      "name": "West Sussex",
      "code": "GB-WSX",
      "subdivision": "two-tier county"
    },
    {
      "name": "Worcestershire",
      "code": "GB-WOR",
      "subdivision": "two-tier county"
    },
    {
      "name": "Bath and North East Somerset",
      "code": "GB-BAS",
      "subdivision": "unitary authority"
    },
    {
      "name": "Bedford",
      "code": "GB-BDF",
      "subdivision": "unitary authority"
    },
    {
      "name": "Blackburn with Darwen",
      "code": "GB-BBD",
      "subdivision": "unitary authority"
    },
    {
      "name": "Blackpool",
      "code": "GB-BPL",
      "subdivision": "unitary authority"
    },
    {
      "name": "Blaenau Gwent",
      "code": "GB-BGW",
      "subdivision": "unitary authority"
    },
    {
      "name": "Bournemouth",
      "code": "GB-BMH",
      "subdivision": "unitary authority"
    },
    {
      "name": "Bracknell Forest",
      "code": "GB-BRC",
      "subdivision": "unitary authority"
    },
    {
      "name": "Bridgend",
      "code": "GB-BGE",
      "subdivision": "unitary authority"
    },
    {
      "name": "Brighton and Hove",
      "code": "GB-BNH",
      "subdivision": "unitary authority"
    },
    {
      "name": "Bristol, City of",
      "code": "GB-BST",
      "subdivision": "unitary authority"
    },
    {
      "name": "Caerphilly",
      "code": "GB-CAY",
      "subdivision": "unitary authority"
    },
    {
      "name": "Cardiff",
      "code": "GB-CRF",
      "subdivision": "unitary authority"
    },
    {
      "name": "Carmarthenshire",
      "code": "GB-CMN",
      "subdivision": "unitary authority"
    },
    {
      "name": "Central Bedfordshire",
      "code": "GB-CBF",
      "subdivision": "unitary authority"
    },
    {
      "name": "Ceredigion",
      "code": "GB-CGN",
      "subdivision": "unitary authority"
    },
    {
      "name": "Cheshire East",
      "code": "GB-CHE",
      "subdivision": "unitary authority"
    },
    {
      "name": "Cheshire West and Chester",
      "code": "GB-CHW",
      "subdivision": "unitary authority"
    },
    {
      "name": "Conwy",
      "code": "GB-CWY",
      "subdivision": "unitary authority"
    },
    {
      "name": "Cornwall",
      "code": "GB-CON",
      "subdivision": "unitary authority"
    },
    {
      "name": "Darlington",
      "code": "GB-DAL",
      "subdivision": "unitary authority"
    },
    {
      "name": "Denbighshire",
      "code": "GB-DEN",
      "subdivision": "unitary authority"
    },
    {
      "name": "Derby",
      "code": "GB-DER",
      "subdivision": "unitary authority"
    },
    {
      "name": "Durham, County",
      "code": "GB-DUR",
      "subdivision": "unitary authority"
    },
    {
      "name": "East Riding of Yorkshire",
      "code": "GB-ERY",
      "subdivision": "unitary authority"
    },
    {
      "name": "Flintshire",
      "code": "GB-FLN",
      "subdivision": "unitary authority"
    },
    {
      "name": "Gwynedd",
      "code": "GB-GWN",
      "subdivision": "unitary authority"
    },
    {
      "name": "Halton",
      "code": "GB-HAL",
      "subdivision": "unitary authority"
    },
    {
      "name": "Hartlepool",
      "code": "GB-HPL",
      "subdivision": "unitary authority"
    },
    {
      "name": "Herefordshire",
      "code": "GB-HEF",
      "subdivision": "unitary authority"
    },
    {
      "name": "Isle of Anglesey",
      "code": "GB-AGY",
      "subdivision": "unitary authority"
    },
    {
      "name": "Isle of Wight",
      "code": "GB-IOW",
      "subdivision": "unitary authority"
    },
    {
      "name": "Isles of Scilly",
      "code": "GB-IOS",
      "subdivision": "unitary authority"
    },
    {
      "name": "Kingston upon Hull",
      "code": "GB-KHL",
      "subdivision": "unitary authority"
    },
    {
      "name": "Leicester",
      "code": "GB-LCE",
      "subdivision": "unitary authority"
    },
    {
      "name": "Luton",
      "code": "GB-LUT",
      "subdivision": "unitary authority"
    },
    {
      "name": "Medway",
      "code": "GB-MDW",
      "subdivision": "unitary authority"
    },
    {
      "name": "Merthyr Tydfil",
      "code": "GB-MTY",
      "subdivision": "unitary authority"
    },
    {
      "name": "Middlesbrough",
      "code": "GB-MDB",
      "subdivision": "unitary authority"
    },
    {
      "name": "Milton Keynes",
      "code": "GB-MIK",
      "subdivision": "unitary authority"
    },
    {
      "name": "Monmouthshire",
      "code": "GB-MON",
      "subdivision": "unitary authority"
    },
    {
      "name": "Neath Port Talbot",
      "code": "GB-NTL",
      "subdivision": "unitary authority"
    },
    {
      "name": "Newport",
      "code": "GB-NWP",
      "subdivision": "unitary authority"
    },
    {
      "name": "North East Lincolnshire",
      "code": "GB-NEL",
      "subdivision": "unitary authority"
    },
    {
      "name": "North Lincolnshire",
      "code": "GB-NLN",
      "subdivision": "unitary authority"
    },
    {
      "name": "North Somerset",
      "code": "GB-NSM",
      "subdivision": "unitary authority"
    },
    {
      "name": "Northumberland",
      "code": "GB-NBL",
      "subdivision": "unitary authority"
    },
    {
      "name": "Nottingham",
      "code": "GB-NGM",
      "subdivision": "unitary authority"
    },
    {
      "name": "Pembrokeshire",
      "code": "GB-PEM",
      "subdivision": "unitary authority"
    },
    {
      "name": "Peterborough",
      "code": "GB-PTE",
      "subdivision": "unitary authority"
    },
    {
      "name": "Plymouth",
      "code": "GB-PLY",
      "subdivision": "unitary authority"
    },
    {
      "name": "Poole",
      "code": "GB-POL",
      "subdivision": "unitary authority"
    },
    {
      "name": "Portsmouth",
      "code": "GB-POR",
      "subdivision": "unitary authority"
    },
    {
      "name": "Powys",
      "code": "GB-POW",
      "subdivision": "unitary authority"
    },
    {
      "name": "Reading",
      "code": "GB-RDG",
      "subdivision": "unitary authority"
    },
    {
      "name": "Redcar and Cleveland",
      "code": "GB-RCC",
      "subdivision": "unitary authority"
    },
    {
      "name": "Rhondda, Cynon, Taff",
      "code": "GB-RCT",
      "subdivision": "unitary authority"
    },
    {
      "name": "Rutland",
      "code": "GB-RUT",
      "subdivision": "unitary authority"
    },
    {
      "name": "Shropshire",
      "code": "GB-SHR",
      "subdivision": "unitary authority"
    },
    {
      "name": "Slough",
      "code": "GB-SLG",
      "subdivision": "unitary authority"
    },
    {
      "name": "South Gloucestershire",
      "code": "GB-SGC",
      "subdivision": "unitary authority"
    },
    {
      "name": "Southampton",
      "code": "GB-STH",
      "subdivision": "unitary authority"
    },
    {
      "name": "Southend-on-Sea",
      "code": "GB-SOS",
      "subdivision": "unitary authority"
    },
    {
      "name": "Stockton-on-Tees",
      "code": "GB-STT",
      "subdivision": "unitary authority"
    },
    {
      "name": "Stoke-on-Trent",
      "code": "GB-STE",
      "subdivision": "unitary authority"
    },
    {
      "name": "Swansea",
      "code": "GB-SWA",
      "subdivision": "unitary authority"
    },
    {
      "name": "Swindon",
      "code": "GB-SWD",
      "subdivision": "unitary authority"
    },
    {
      "name": "Telford and Wrekin",
      "code": "GB-TFW",
      "subdivision": "unitary authority"
    },
    {
      "name": "Thurrock",
      "code": "GB-THR",
      "subdivision": "unitary authority"
    },
    {
      "name": "Torbay",
      "code": "GB-TOB",
      "subdivision": "unitary authority"
    },
    {
      "name": "Torfaen",
      "code": "GB-TOF",
      "subdivision": "unitary authority"
    },
    {
      "name": "Vale of Glamorgan, The",
      "code": "GB-VGL",
      "subdivision": "unitary authority"
    },
    {
      "name": "Warrington",
      "code": "GB-WRT",
      "subdivision": "unitary authority"
    },
    {
      "name": "West Berkshire",
      "code": "GB-WBK",
      "subdivision": "unitary authority"
    },
    {
      "name": "Wiltshire",
      "code": "GB-WIL",
      "subdivision": "unitary authority"
    },
    {
      "name": "Windsor and Maidenhead",
      "code": "GB-WNM",
      "subdivision": "unitary authority"
    },
    {
      "name": "Wokingham",
      "code": "GB-WOK",
      "subdivision": "unitary authority"
    },
    {
      "name": "Wrexham",
      "code": "GB-WRX",
      "subdivision": "unitary authority"
    },
    {
      "name": "York",
      "code": "GB-YOR",
      "subdivision": "unitary authority"
    }
  ]
};
