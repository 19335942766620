
  import Vue from 'vue'

  export default Vue.extend({
    name: "pwa-email-verification",
    props: {
      subtitle: {
        type: String,
        default: null,
        required: true,
      }
    }
  });
