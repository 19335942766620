
  import Vue from 'vue';
  import {ScrollPicker, ScrollPickerGroup} from "vue-scroll-picker";
  import {Portal} from 'portal-vue';
  import "vue-scroll-picker/dist/style.css";
  import moment from "moment-timezone";
  import {USER_DATE_FORMAT} from "@/utils/date";
  // Formato interno de las fechas del componente
  const datesFormat = 'YYYY-MM-DD';

  export default Vue.extend({
    name: "pwa-date-picker",
    components: {
      ScrollPicker,
      ScrollPickerGroup,
      Portal,
    },
    props: {
      value: {
        type: String,
        required: false,
        default: null,
      },
      min: {
        type: String,
        required: false,
        default: null,
      },
      max: {
        type: String,
        required: false,
        default: null,
      },
      placeholder: {
        type: String,
        required: false,
        default: '',
      },
      name: {
        type: String,
        required: false,
        default: null,
      },
      state: {
        type: Boolean,
        required: false,
        default: null,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      dateFormat: {
        type: String,
        required: false,
        default: USER_DATE_FORMAT,
      },
      onDateSelected: {
        type: Function,
        required: false,
      },
    },
    data() {
      const currentDate = moment();
      return {
        show: false,
        selectedDate: null,
        selectedDateFormatted: '',
        selectedYear: currentDate.year(),
        selectedMonth: currentDate.month() + 1,
        selectedDay: currentDate.date(),
        monthNames: moment.months(),
        maxDate: null,
        minDate: null,
      };
    },
    mounted() {
      this.initData();
    },
    computed: {
      years(): number[] {
        const currentYear = moment().year();
        const lastYear = this.maxDate ? this.maxDate.year() : currentYear;
        const firstYear = this.minDate ? this.minDate.year() : (currentYear - 100);
        return Array.from({length: lastYear - firstYear + 1}, (_, index) => firstYear + index);
      },
      months(): { value: number, name: string }[] {
        return Array.from({length: 12}, (_, index) => ({value: index + 1, name: this.monthNames[index]}));
      },
      days(): number[] {
        const lastDay = new Date(this.selectedYear, this.selectedMonth, 0).getDate();
        return Array.from({length: lastDay}, (_, index) => index + 1);
      },
      numDays(): number {
        return this.days.length;
      },
    },
    methods: {
      getDateNumberPrefix(date: number): string {
        return date < 10 ? `0${date}` : date.toString();
      },
      initData(): void {
        this.maxDate = this.max ? moment(this.max, datesFormat) : moment().add(20, 'years');
        this.minDate = this.min ? moment(this.min, datesFormat) : moment().subtract(100, 'years');
        this.initSelectedDate();
      },
      initSelectedDate(): void {
        let currentDate = null;
        if (this.selectedDate) {
          currentDate = this.selectedDate;
        } else if (this.value) {
          currentDate = moment(this.value, datesFormat);
          this.selectedDate = currentDate;
        } else {
          currentDate = moment();
        }

        let initDate = currentDate;
        if (currentDate > this.maxDate) {
          initDate = this.maxDate;
        } else if (currentDate < this.minDate) {
          initDate = this.minDate;
        }

        this.selectedYear = initDate.year();
        this.selectedMonth = initDate.month() + 1;
        this.selectedDay = initDate.date();
        if (!this.selectedDateFormatted && this.selectedDate) {
          this.selectedDateFormatted = this.selectedDate.format(this.dateFormat);
        }
      },
      hidePanel(): void {
        this.show = false;
      },
      showPanel(): void {
        if (!this.disabled) {
          this.show = true;
        }
      },
      onSelectDateClick(): void {
        this.selectedDate = moment(`${this.selectedYear}-${this.getDateNumberPrefix(this.selectedMonth)}-${this.getDateNumberPrefix(this.selectedDay)}`, datesFormat);
        this.selectedDateFormatted = this.selectedDate.format(this.dateFormat);
        if (this.onDateSelected) {
          this.onDateSelected(this.selectedDate.format(datesFormat));
        }
        this.hidePanel();
      },
      onCancelDateClick(): void {
        this.hidePanel();
        this.initSelectedDate();
      },
    },
    watch: {
      numDays() {
        if (this.selectedDay > this.numDays) {
          this.selectedDay = this.numDays;
        }
      },
      currentLang() {
        this.monthNames = moment.months();
        if (this.selectedDate) {
          this.selectedDate = moment(this.selectedDate.format(datesFormat), datesFormat);
          this.selectedDateFormatted = this.selectedDate.format(this.dateFormat);
        }
      },
      value() {
        this.initData();
      },
      max() {
        this.initData();
      },
      min() {
        this.initData();
      },
    }
  });
